<template>
  <Breadcrumb menu="利用規約" />
  <div class="page-header">利用規約</div>
  <div class="content">
    <p>
      この利用規約（以下、「本規約」といいます。）は、株式会社アスラク（以下「当社」といいます）は、がこのウェブサイト上で提供するサービス（以下、「本サービス」といいます。）の利用条件を定めるものです。
      ご利用のお客様の皆さま（以下、「お客様」といいます。）が代行サービスを依頼する際は、下記の規約に同意したものとみなします。
    </p>
    <p class="title">第1条、ご注文とお支払い方法について</p>
    <p>
      ご注文は、専用ページよりご記入いただき、金額を確認して頂いた後で、ご入金日の為替レート＋1円を日本円に換算し、ご請求させて頂きます。
      お支払い方法は、指定する銀行口座へ振り込み頂くことになります。<br>
      ご入金が確認できましたら、買い付けさせて頂きます。
    </p>
    <p class="title">第2条、検品について</p>
    <p>
      <ol>
        <li>
          当社にて行う検品はですが、簡易検品となり、数量、破損、サイズ、カラーの相違を開梱し、検品させて頂きます。
        </li>
        <li>
          本サービスは、下記の検品は通常行わないものとします。
          <ul>
            <li>電気製品の電源を入れた動作確認</li>
            <li>記録媒体の再生、閲覧、容量の確認</li>
            <li>ブランド品の真偽</li>
            <li>サイト上にある商品情報の相違確認</li>
          </ul>
        </li>
      </ol>
    </p>
    <p class="title">第3条、返品・返金について</p>
    <p>
      当社に到着していた段階で不良品の場合は、店舗に交渉し交換またはキャンセルさせて頂きます。その後、返金させて頂きます。口座への返金手数料につきましてはお客様負担となります。
      もしも店舗が拒否した場合は、当社では責任を負うことができませんので、ご了承ください。<br>
      日本に到着後に商品を返品する場合の中国までの返送料は、お客様負担とさせて頂きます。商品が、イメージと違うなどの理由の場合は、返品できない場合がございますので、お客様の自己責任でお願い致します。
    </p>
    <p class="title">第4条、免責事項</p>
    <p>
      下記の内容につきましては、当社では責任を負えませんのでご注意ください。<br>
      <ul>
        <li>買付け後の途中キャンセル</li>
        <li>コピー製品・模倣品の取扱い</li>
        <li>税関で没収や不在の為、返送となった場合</li>
        <li>輸送中の災害や故障した場合（配送業者の保障範囲に準ずる）</li>
        <li>税関で課せられた関税</li>
        <li>タオバオやアリババの商品の品質</li>
      </ul>
    </p>
    <p class="title">第5条、商品の保管について</p>
    <p>
      商品は保管期限は約1ヶ月とさせて頂きます。保管期間が過ぎましたら処分させて頂きますので、ご注意ください。
      処分によって生じた、損害があった場合も責任は負えません。
    </p>
    <p class="title">第6条、利用制限および登録抹消</p>
    <p>
      <ol>
        <li>
          当社は、お客様が以下のいずれかに該当する場合には、事前の通知なく、お客様に対して、本サービスの全部もしくは一部の利用を制限し、
          またはお客様としての登録を抹消することができるものとします。
          <ul>
            <li>本規約のいずれかの条項に違反した場合</li>
            <li>料金等の支払債務の不履行があった場合</li>
            <li>当社からの連絡に対し、一定期間返答がない場合</li>
            <li>本サービスについて、最終の利用から一定期間利用がない場合</li>
            <li>その他、当社が本サービスの利用を適当でないと判断した場合</li>
          </ul>
        </li>
        <li>当社は、本条に基づき当社が行った行為によりお客様に生じた損害について、一切の責任を負いません。</li>
      </ol>
    </p>
    <p class="title">第7条、利用規約の変更</p>
    <p>
      当社は、必要と判断した場合には、お客様に通知することなくいつでも本規約を変更することができるものとします。
      なお、本規約の変更後、本サービスの利用を開始した場合には、当該お客様は変更後の規約に同意したものとみなします。
    </p>
    <p>
      第一版<br>
      2021年9月16日
    </p>
  </div>
</template>

<script>
import Breadcrumb from '../components/atoms/Breadcrumb.vue'

export default {
  name: 'News',
  components: {
    Breadcrumb,
  },
  data() {
  },
}
</script>

<style lang="scss">
.page-header {
  background: #ecf4f7;
  text-align: center;
  font-size: 23px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  border-top: #cee7f0 1px solid;
  color: #5c63ae;
}

.content {
  margin: auto;
  padding-top: 20px;
  min-height: 46vh;
  max-width: 900px;
  .title {
    padding-top: 10px;
    padding-bottom: 5px;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid rgb(212, 199, 199);
  }
  li {
    line-height: 200%;
  }
}

@media (min-width: 751px) {
  .content {
    width: 80%;
  }
}

@media screen and (max-width: 750px) {
  .content {
    width: 90%;
  }
}
</style>